import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import Layout from '@components/layout'

const HaulagePage = () => {
  return (
    <Layout pageTitle="Haulage">
      <Container fluid="xxl">
        <Row>
          <Col className="no-gutters">
            <div className="hero-banner large-hero">
              <StaticImage
                className="hero-image"
                src="../../../media/bypass_hero.png"
                alt=""
                aspectRatio={3.75 / 1}
                layout="fullWidth"
              />
              <div className="hero-fade"></div>
              <div className="hero-text">
                <h2>Services</h2>
                <h1>Haulage</h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <main>
        <Container fluid="xxl" className="section default-text">
          <Row className="align-items-center" md={2} xs={1}>
            <Col>
              <h3>Why Haulage?</h3>
              <p>
                There are many situations where the haulage of waste is needed including the emptying of lagoons for inspection and repair, emergency situations such as flooding, force main break, or pumping station failure.  We also provide haulage for new subdivisions waiting for sewage systems.
              </p>
              <p>
                When flooding overwhelms the systems and equipment in place, Wessuc is called in to pump and haul out water and waste until the situation returns to normal conditions. For new subdivisions, we  bring our trucks in on a continual basis to remove the sewage  until permanent systems are operational.
              </p>
            </Col>
            <Col>
              <StaticImage
                src="../../../media/Wessuc-35.jpg"
              />
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl">
          <Row>
            <Col>
              <h3>It's what we do!</h3>
              <p>
                At Wessuc, we haul bulk liquid and solids from virtually any site or location. Our large fleet of trucks and equipment are designed to maximize volumes and reduce back-and-forth traffic. With 6 inch discharge capability, 43-45 m3 tankers, and large dump trailers, we lead the way in safe, efficient, and environmentally responsible haulage of waste material.
              </p>
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  )
}

export default HaulagePage